import React from 'react'
import Layout from '../components/theme/Layout'
import Footer from '../components/theme/Footer'
import Header from '../components/theme/Header'
import BlueHeader from '../components/common/BlueHeader'

const NotFoundPage = () => (
  <Layout
    meta={{}}
    urls={{
      'en': `/404`,
      'fr': `/fr/404`
    }}
    locale='en'
  >
    <Header
      switchPath='/fr/404'
      locale='en'
    />

    <BlueHeader title='PAGE NOT FOUND' />

    <Footer />

  </Layout>
)

export default NotFoundPage

